.btn {
  border-radius: 2.5rem;
  padding: 1rem;

  &.cancel {
    background: $light;
    color: $primary;
    border-color: #0E0641;
  }
}

.btn-auth {
  font-size: 1.25rem;

  &:hover {
    text-decoration: none !important;
  }
}

.qr-btn {
  border: none;
  border-radius: .5rem;
  background-color: $qr-main;
  @include setClamp(font-size, 1rem, 5vw, 1.333333rem, 1.333333rem);

  &.white {
    background-color: $white;
    color: $primary;
    border: 1px solid $border;
  }
  &.disabled {
    background: $qr-disabled;
  }

  &.cancel {
    background: $light;
    color: $primary;
  }

  &.end-conv {
    background-color: $white;
    color: $qr-danger;
    border: 1px solid $qr-danger;
  }

  &.media_permissions {
    font-size: 12px;
    font-weight: 600;
  }

  &:disabled {
    background: $qr-disabled;
  }
}
