.chat-configuration-editable {
  @include setClamp(font-size, 1rem, 5vw, 1.333333rem, 1.333333rem);
  color: $primary;
  margin-bottom: 2rem;
  line-height: 1;

  &__title {
    font-weight: bold;
    margin-bottom: .8rem
  }

  &__subtitle {
    line-height: 1.4;
    @include setClamp(font-size, 0.75rem, 5vw, 1rem, 1rem);
    margin-bottom: 0.5rem;
    padding-right: 2.2rem!important;
  }

  &__content {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }

  &.toggle-button {
    display: flex;
  }

  &.toggle-button__content {
    align-self: center;
    margin: 1rem;
    text-align: end;

    .toggle-buttons {
      display: flex!important;
    }
  }
}
