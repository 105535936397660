.check-validation {
  padding-bottom: 10px;
  font-size: 14px;
  text-align: start;

  .circle {
    padding: 5px;
    border-radius: 50%;
    background-color: #8BD8BD;
    opacity: .3;
    margin-right: 8px;
    fill: white;  
  }

  .circle--active {
    background-color: #8BD8BD;
    opacity: 1;
  }
}

.signup-confirmation {
  max-width: 391px;

  &--section{
    padding: 0rem 30px;
  }
}
