// border radius examples from tailwindcss framework.
// bootstrap doesn't have font-size pre-made style
.rounded-none {
  border-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

// already set by bootstrap
// .rounded {
//   border-radius: 0.25rem;
// }

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}
