.messagesLeft {
  padding: 2rem 3.5rem;
   
  span {
    font-weight: 700;
  }

  p {
    font-size: 14px;
    color: #767676;
  }

  hr {
    width: 100%;
    height: 2px;
    background-color: #F7F9FF;
    margin: 0;
    padding: 0;
    border: 0;
  }

  &__button {
    font-size: 16px;
    display: flex;
    align-items:  center;
    background-color: white;
    color: black;
    padding: .8rem;
    border: none;
    font-weight: bold;
    box-shadow: 0px 2px 16px rgba(36, 54, 101, 0.1)!important;

    &:hover,
    &:active,
    &:focus {
      background-color: white;
      color: black;
    }
  }

  &__content {
    &__label {
      flex: 0 0 40%;
    }

    &__details {
      flex: 0 0 60%;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      display: flex;
    }

    &__active {
      font-weight: bold;
    }

    &__list {
      padding-top: 1rem;
      padding-bottom: 1rem;
      list-style: none;

      .previous,
      .next {
        border-radius: 5px;
        box-shadow: 0px 2px 10px rgba(36, 54, 101, 0.1);
        padding-bottom: .4rem;
      }

      a {
        padding-left: .8rem;
        padding-right: .8rem;
        font-size: 16px;
      }
    }
  }
}

.messagesLeft__header {
  @extend .w-100, .d-flex, .justify-content-between, .align-items-center !optional;
  margin-top: -1rem; // To revert the positive margin from the user stats component
  font-family: 'Lato', sans-serif;
  color: $primary;
  position: relative;
  padding-bottom: 1.25rem;

  &__title {
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }

  &__subtitle {
    font-size: 1.33333rem;
  }
}

.messagesLeft__body {
  &__button {
    @extend .btn-success !optional;
    font-size: 14px;
    display: flex;
    align-items:  center;
    padding: 0px 35px 0px 35px;
    border: none;
    font-weight: bold;
    box-shadow: 0px 2px 16px rgba(36, 54, 101, 0.1)!important;
  }

}

.messagesLeft__filters-button {
  @extend .d-flex, .justify-content-between, .align-items-center !optional;
  border-radius: 3.75rem;
  cursor: pointer;
  padding: 0.75rem 1rem;
  box-shadow: 0 2px 16px rgba(36, 54, 101, 0.1);
  font-size: 1.25rem;
  font-family: Lato, sans-serif;
  font-weight: bold;
  transition: all 0.2s ease-in;

  // Filters SVG Icomoon icon
  svg {
    margin-right: 3px;
  }

  &--open {
    background-color: hsl(248.2, 83.6%, 13.9%);
    transition: all 0.2s ease-in;
    color: #fff;

    svg path {
      fill: #fff;
    }

    &:hover {
      background-color: hsl(248.2, 83.6%, 8%);
      transition: all 0.2s ease-in;
    }
  }

  &--closed {
    background-color: hsl(0, 0%, 100%);
    color: hsl(248.2, 83.6%, 13.9%);
    transition: all 0.2s ease-in;

    svg path {
      fill: hsl(248.2, 83.6%, 13.9%);
    }

    &:hover {
      background-color: hsl(0, 0%, 98%);
      transition: all 0.2s ease-in;
    }
  }
  &--spinner{
    display: flex;
    margin-right: 3px;
    .spinner-border {
      color: white !important;
    }
  }
}

.messagesLeft__filter-field {
  &__container {
    @extend .d-flex, .flex-column, .mb-3 !optional;
  }

  &__label {
    @extend .mb-3 !optional;
    font-family: Lato, sans-serif;
    font-size: 1.33333rem;
    color: $primary;
    font-weight: bolder;
  }


  &__ratings-container {
    @extend .d-flex !optional;
  }

  &__rating {
    @extend .d-flex, .align-items-center, .px-2, .me-2 !optional;
    width: 40px;
    border: 1px solid #767676;
    border-radius: 24px;
    cursor: pointer;
    justify-content: space-around;
    transition: all 0.15s ease-in-out;

    svg path {
      fill: #8BD8BD !important;
    }

    &--selected {
      color: $white;
      background-color: $primary;
      border: 1px solid $primary;
      transition: all 0.15s ease-in-out;
    }
  }
}
