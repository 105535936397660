.chat-configuration {
  justify-content: center;
  width: 100%;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
  }

  &__stepper {
    margin: auto;
    flex-grow: inherit;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $primary;

    &__buttons{
      display: flex;
      justify-content: center;
      flex-grow: 1;

      button {
        font-weight: bold;
        width: 120px;
      }
    }

    &__content {
      &__title {
        font-weight: 900;
        font-size: 2rem;
        margin-bottom: 0.75rem;
      }
    
      &__subtitle {
        font-size: 1.33333rem;
        padding-bottom: 1rem;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0rem;
    align-self: baseline;

    &.top {
      padding: 1rem 0rem;

      button {
        &.disabled {
          font-weight: bold;
          border: none;
          background-color: #F5F5F5!important;
        }

        &.enabled {
          font-weight: bold;
          border: none;
          
          &:active {
            background-color: #F5F5F5!important;
          }
        }
      }
    }

    button {
      width: 14rem;
    }
  }
}

.chat-viewable {
  height: 100%;
  width: 256px;
  font-family: "Nunito", sans-serif;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 1.78451px 14.2761px rgba(36, 54, 101, 0.1);

  padding-top: 1rem;
  @include setClamp(padding-left, 0.5rem, 1.5vw, 3.5rem, 3.25rem);
  @include setClamp(padding-right, 0.5rem, 1.5vw, 3.5rem, 3.25rem);
  padding-bottom: 0.75rem;

  color: $qr-text-color;
  white-space: pre-line;
  text-align: center;
  overflow-wrap: break-word;

  p, button, textarea, input {
    @include setClamp(font-size, 0.6rem, 1vw, 1rem, 1rem);
  }

  p {
    &.first-line {
      &::first-line{
        font-weight: bold!important;
      }
    }
  }

  span, label {
    @include setClamp(font-size, 0.6rem, 0.8vw, 1rem, 0.8rem);
  }
  
  h4 {
    @include setClamp(font-size, 1.2rem, 1vw, 2rem, 2rem);
  }

  &__logos-placeholder {
    display: flex;
    justify-content: space-evenly;
    
    img {
      max-width: 10rem;
      max-height: 3.75rem;
    }
  
    &--separator {
      border: 1px solid #DADCE0;
      width: auto!important;
    }
  }

  &__title {
    margin-bottom: 1.5rem;
    text-align: left;
    letter-spacing: 0.02em;
    @include setClamp(font-size, 1rem, 5vw, 1.4rem, 1.25rem);
    line-height: 150%;
    font-weight: bold;
    padding-bottom: 0.5rem;
    position: relative;

    &__line {
      content: '';
      bottom: 0;
      left: 0;
      @include setClamp(width, 35px, 11.75vw, 45px, 42px);
      height: 4px;
      position: absolute;
      background: #3273E0;
      border-radius: 10px;
    }
  }

  button {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.chat-editable {
  padding: 0 1rem;
  textarea {
    font-size: 14px;
    line-height: 1.4;
    width: inherit;
    border: none;
    border-radius: 20px;
    background-color: $light;
    color: $primary;
    padding: 1rem;
    resize: none;
    white-space: pre-wrap;
  }

  &__toggle-buttons {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 48px;
    cursor: pointer;
    
    &__title {
      font-size: 13px;
      font-weight: bold;
      margin-right: 1rem;
    }
  }

  &__btn {
    width: 48px;
    height: 24px;
    border: none;
    background-color: #f5f5f5;
    border-radius: 50%;
    z-index: 10;
    padding: 0;

    &:hover {
      box-shadow: none!important;
      color: transparent!important;
      background-color: $success !important;
    }

    &:active {
      box-shadow: none!important;
      color: transparent!important;
      background-color: $success !important;
    }

    &:focus {
      box-shadow: none!important;
      color: transparent!important;
      background-color: $success !important;
    }
  }

  &__active {
    left: 24px;
    box-shadow: none;
    background-color: $success;
    outline: none;
    border: none;
    z-index: 0;
    width: 24px;
  }

  &__passive {
    position: relative;
    width: 24px;
    background-color: #cccccc;

    &:hover {
      box-shadow: none!important;
      color: transparent!important;
      background-color: #cccccc !important;
    }

    &:active {
      box-shadow: none!important;
      color: transparent!important;
      background-color: #cccccc !important;
    }

    &:focus {
      box-shadow: none!important;
      color: transparent!important;
      background-color: #cccccc !important;
    }
  }
}
