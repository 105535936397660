.chat-basics-editable {
  margin-right: 1rem;
  max-height: 545px;
  overflow-x: hidden;

  &__color {
    display: flex;
    align-items: center;
    vertical-align: middle;

    &__input {
      margin-right: 1rem;

      input {
        width: 30px;
        height: 30px;
        border: none;
        background-color: transparent;
      }
    }
  }

  &__logo {
    width: 100%;
    display: flex;
    min-height: 30px;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    margin-top: 1rem;

    img {
      max-width: 100px;
      height: 30px;

      &.hidden {
        display: none;
      }
    }

    input[type="file"] {
      display: none;
    }

    &__upload {
      color: $success;
      text-decoration: underline;
    }

    &__delete {
      color: $danger;
    }
  }
}

.chat-basics-viewable {
  background-color: $white;
  font-family: Nunito, sans-serif;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 1.78451px 14.2761px rgba(36, 54, 101, 0.1);
  padding: 1rem;

  &__header {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 1rem;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #DADCE0;
      position: absolute;
      bottom: 0;
    }

    &__company-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      &__worker-name {
        font-size: 13px;
        font-weight: bold;
      }

      &__logo {
        max-height: 1.875rem; //around 30px
      }
    }
  }

  &__btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__btn {
      @extend .qr-btn !optional;
      font-size: 10px;
      padding: 0.2rem;
      width: 47%;
      border-radius: 3.5px;
      height: 25px;
      pointer-events: none;

      &--danger {
        @extend .end-conv !optional;
        &--disabled {
          background-color: #fff !important;
          color: #ccc !important;
          border: 1px solid #cccccc !important;
        }
      }
    }
  }

  &-chat {
    font-family: "Nunito", sans-serif;
    font-size: 1.16666666667rem !important;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;

    &__messages-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 10px;
    }
  
    &__message-list {
      overflow-y: auto;
      width: 100%;
      
      &__customer {
        display: flex;
        justify-content: flex-end;
        color: $white;
        width: 100%;
        margin: 0;
        padding: .5rem 0;

        &-content {
          font-size: 10px;

          &__message {
            width: 100%;
            padding: 1rem;
            background-color: $light;
            border-radius: 10px;
            white-space: pre-line;
            word-break: break-word;
            word-wrap: break-word;
          }

          &__timesnap {
            color: $default;
            text-align: end;
            padding-top: .5rem;
          }
        }

        &__avatar {
          padding-left: 1rem;
          img {
            border-radius: 10px;
            width: 40px;
            height: 40px;
          }
        }
      }

      &__worker {
        display: flex;
        justify-content: flex-start;
        color: $white;
        width: 100%;
        margin: 0;
        padding: .5rem 0;

        &-content {
          font-size: 10px;

          &__message {
            width: 100%;
            padding: 1rem;
            color: $primary;
            background-color: $light;
            border-radius: 10px;
            white-space: pre-line;
            word-break: break-word;
            word-wrap: break-word;
          }

          &__timesnap {
            color: $default;
            padding-top: .5rem;
            text-align: start;
          }
        }

        &__avatar {
          padding-right: 1rem;

          img {
            border-radius: 10px;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  
    &__input {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 !important;
      
      input[type="text"],
      input[type="text"]:active,
      input[type="text"]:focus {
        font-size: 10px;
        background-color: $white !important;
        border: 1px solid $border;
        border-radius: .5rem;
      }
    }
  }  
}
