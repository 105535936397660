.checkout-layout {
  &__bg-images {
    background-image: url('../assets/images/checkout/y.svg'),
      url('../assets/images/checkout/half-circle-right.svg'),
      url('../assets/images/checkout/half-circle-left.svg');
    background-position: 0% 35%,
                         top right,
                         0% 90%;
    background-repeat: no-repeat;
    background-color: $light;
  }

  &__logo {
    margin-left: 10rem;
  }
}
