.onboarding-info {
  &__header {
    @extend .d-flex, .justify-content-between, .align-items-center !optional;

    &__title {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
    }

    &__log-out {
      font-weight: bold;
      font-size: 16px;
    }
  }
  
  .form-group {
    margin: 0;
  }

  &__field {
    color: $primary;
    font-family: 'Lato', sans-serif;
    margin: 1.75rem 0;

    &__label {
      width: 26rem;
      margin-right: 7rem;
      align-self: start;

      &__title {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
      }
    }

    &__input {
      color: #495057 !important;
      background-color: $light !important;
      border-radius: 1.7rem !important;
      width: 275px;
      min-height: 38px;
      height: 38px !important;
      font-size: 1.15rem;
      border: 0 !important;
      text-indent: 0.75rem !important;

      &:focus {
        border: 0 !important;
        border-color: #2711b6 !important;
        box-shadow: 0 0 0 0.2rem rgba(14, 6, 65, 0.25) !important;
        outline: 0 !important;
        background-color: $white !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
      }

      // Red shadow around the input when there is an error
      &.error {
        box-shadow: 0 0 0 0.075rem rgb(255, 8, 8) !important;
      }
    }
  }
}
