.team-members {
  padding: 2rem 0.5rem;

  &__table-data {
    font-size: 16px;
    color: #767676;

    :not(:first-child) {
      text-align: center;
    } 
  }

  &__loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 250%);
  }

  &__table-row {
    border-bottom: 2px solid #F7F9FF;
    height: 50px;
  
    &__header {
      font-weight: normal;
    }
  }

  &__table-header {
    font-size: 14px;
    border-bottom: 2px solid $lighter;
  }

  &__edit-team-memeber {
    font-size: 16px;
  
    &__label {
      font-weight: bold;
    }
  
    &__input {
      color: #767676;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14rem;
    height: 36px;
    font-size: 16px;
    border: none;
    background-color: $success;
    border-radius: 20px;
    z-index: 10;
  }

  &__active {
    box-shadow: none;
    background-color: #F7F9FF;
    color: $default;
    outline: none;
    border: none;
    z-index: 0;

    &:hover,
    &:active,
    &:focus {
      background: #F7F9FF;
    }
  }

  &__pending-btn {
    margin-left: -20px;
  }

  &__header {
    @extend .w-100, .d-flex, .justify-content-between, .align-items-center !optional;
    margin-top: -1rem; // To revert the positive margin from the user stats component
    font-family: 'Lato', sans-serif;
    color: $primary;
    position: relative;
    padding-bottom: 1.25rem;
  
    &__title {
      font-weight: 900;
      font-size: 2rem;
      margin-bottom: 0.75rem;
    }
  
    &__subtitle {
      font-size: 1.33333rem;
    }
  }
}
