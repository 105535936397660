.dashboard_tabs{
    & >.tab-content{
        padding: 10px 0px 10px 0px;
        align-self: stretch !important;
        height: 90% !important;
        & >.tab-pane{
            align-self: stretch !important;
            height: 100% !important;
        }
    }    
    &__tab{
        align-self: stretch !important;
        height: 100% !important;
        background-color: #ffffff !important;
        border: 0px !important;
        color: rgba(0, 0, 0, 0.4);
        border-radius: 0px !important;
        font-weight: 600;
        &.show, &.active{
            background-color: #ffffff !important;
            border: 0px !important;
            border-bottom: 3px solid rgb(138,216,188) !important;
            color: rgba(0, 0, 0, 1);
            border-radius: 0px !important;
        }
    }
    &__team_chat{
        align-self: stretch !important;
        height: 100% !important;
        width: 100% !important;
    }
}