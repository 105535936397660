.chat-personal-info-editable {
  margin-right: 1rem;
  max-height: 545px;
  overflow-x: hidden;
  width: 100%;
  flex-basis: auto;
}

.chat-personal-info-viewable {
  height: 100%;
  &__input {
    border-radius: 5px !important;
    background-color: #F5F5F5 !important;
    width: 100% !important;
    padding-left: 1rem !important;
    border: none !important;
    resize: none;
  }

  &__checkbox {
    display: flex;

    label {
      font-size: 10px;
      padding-left: 1rem;
    }
  }
}
