.entry-point-details {
  &__field {
    display: flex;
    justify-content: flex-start;
  }

  &__url {
    margin-right: 1rem;
  }
}

// Overwrite the font size for entry point details 
.chat-settings__field__form-control {
  font-size: 16px;
}
