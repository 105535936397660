.screen--share {
    padding: 5vh 13vh 0px;
    .screen{
        height: 80vh;
        background-color: #171717;
        border-radius: 15px;
        .controls{
          background-color: rgba($color: #3D3D3D, $alpha: 0.4);
          position: absolute;
          bottom: 0;
          z-index: 1;
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
          height: 0;
          transition: .1s ease;
          display: none !important;
        }
    }
    .screen:hover{
      .controls{
        display: flex !important;
        height: 4rem;
      }
    }
    &-client{
      padding: 10vh 15vh 0px;
      .screen{
          height: 80vh;
          border-radius: 15px;
          &.--dark{
            background-color: #171717;
          }
          .controls{
            background-color: rgba($color: #3D3D3D, $alpha: 0.4);
            position: absolute;
            bottom: 0;
            z-index: 1;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            height: 0;
            transition: .1s ease;
            display: none !important;
          }
      }
      .screen:hover{
        .controls{
          display: flex !important;
          height: 4rem;
        }
      }
    }
    .copy-container{
        user-select: none;
        .copy{
            font-size: 1.5vh;
        }
    }
    .copy-container:hover{
        background-color: $light;
    }
    .copy-container:active{
        animation: jelly 0.5s;
    }
    .link{
        font-size: 1.6vh;
    }
    .button{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1.5vh;
        height: 4.5vh;
        width: 100%;
        &--fixed-width {
          width: 121px;
        }
      
        &--max-width {
          width: 100%;
        }
        &.start{
            @extend .btn-success !optional;
        }
        &.stop{
            @extend .btn-danger !optional;
        }
        &.end{
            @extend .btn-outline-danger !optional;
            background-color: white;
        }
    }
}

@keyframes jelly {
    0%,
    100% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }