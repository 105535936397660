.page-item.active .page-link{
  color: black;
  background: none;
  font-weight: 900;
}

.page-link{
  display: flex;
  align-items: center;
  justify-content: center;
  position: unset;
  color: black;
  border: none;
  margin-top: 10px;
  margin-left: 0;
  padding: 0px 14px;
  line-height: 0;
  width: 22px;
  height: 22px;

  span {
    font-size: 1.8rem;
    padding-bottom: .4rem;
    font-weight: 700;
    color: black;
  }
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  box-shadow: 0px 2px 10px rgba(36, 54, 101, 0.1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
