.chat-thanks-editable {
  margin-right: 1rem;
  max-height: 545px;
  overflow-x: hidden;
  width: 100%;
  flex-basis: auto;
}

.chat-thanks-viewable {
  height: 100%;
  &__input {
    border-radius: 5px !important;
    background-color: #F5F5F5 !important;
    width: 100% !important;
    padding-left: 2rem !important;
    border: none !important;
    resize: none;
    font-size: 12px !important;
    padding: 1rem;
  }
}
