.billing__plan-new {

  &__btn-choose-plan {
    max-width: 180px;
    max-height: 48px;
    text-align: center;
    width: 100%;
    height: 100%;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.25rem;
    line-height: 1.75rem;
    border-radius: 2.5rem;
    background-color: $success;
    color: $primary;
  }

  &__btn-choose-plan p {
    display: block;
  }

  &__btn-choose-plan:hover {
    text-decoration: none;
    background-color: #6ecead;
  }
}
