// Bootstrap overridden and new variables
$body-bg: #fff;
$primary: #0E0641;
$primary-light: #3e3867;
$success: #8BD8BD;
$danger: #EC5A4B;
$default: #767676;
$lighter: #F7F9FF;
$light: #F5F5F5;
$border: #E5E5E5;
$consumer-bg: #DDBE8C;
$white: #FFFFFF;
$qr-main: #3273E0;
$qr-disabled: #3273e04d;
$qr-text-color: #3D4043;
$qr-danger: #C94031;

$link-color: $success;
$link-hover-color: $primary;

$font-family-base: 'Lato', sans-serif;

$border-radius-base: 1.583333333rem;

$theme-colors: (
  "primary": $primary,
  "primary-light": $primary-light,
  "success": $success,
  "danger": $danger,
  "default": $default,
  "light": $light,
  "lighter": $lighter,
  "consumer": $consumer-bg,
  "border": $border,
  "transparent": $white
);
