.upload-profile-settings__profile-photo {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  &__spinner {
    display: flex;
    width: 7rem;
    height: 7rem;
  }

  p {
    font-size: 16px;
  }

  &__label {
    width: 7rem;
    height: 7rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #F5F5F5;
  }

  &__img{
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 4rem;
    height: 4rem;
  }

  &__input {
    display: none;
  }
}
