.date-range-picker {
  display: contents;
  .date-picker {
    position: absolute;
    left: 42%;
    z-index: 999;

    &--calendar {
      border: 0.083rem solid red;
      box-shadow: 0rem 0.167rem 1.333rem rgba(36, 54, 101, 0.2);
      border-radius: 0.833rem;
    }
  }

  .react-datepicker {
    display: flex;
    border: none !important;
    box-shadow: 0rem 0.167rem 1.333rem rgba(36, 54, 101, 0.2);
    border-radius: 0.833rem;

    &__input-container {
      input {
        @extend .sb-input;

        &:focus,
        .timezone__control--is-focused {
          @extend .sb-input--focus;
        }

        width: 100%;
      }

      .hide-input {
        display: none !important;
      }
    }

    &__triangle {
      display: none;
    }

    &__day--selected,
    &__day--in-selecting-range,
    &__day--in-range,
    &__month-text--selected,
    &__month-text--in-selecting-range,
    &__month-text--in-range,
    &__quarter-text--selected,
    &__quarter-text--in-selecting-range,
    &__quarter-text--in-range,
    &__year-text--selected,
    &__year-text--in-selecting-range,
    &__year-text--in-range {
      background-color: #8BD8BD !important;
      font-weight: bold;
    }

    &__day--004,
    &__day,
    &__day--selected,
    &__day--range-start,
    &__day--in-range,
    &__day--weekend {
      width: 2.5rem !important;
      height: 2.5rem;
      display: flex !important;
      justify-content: center;
      align-items: center;
      color: black !important;
    }

    &__day-names {
      font-weight: bold;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 0.417rem;
    }

    &__day--disabled {
      color: #ccc !important;
    }

    &__week {
      display: flex;
      justify-content: space-around;
      padding-top: 0.833rem;
    }

    &__month-container {
      width: 29.167rem;
      height: 29.167rem;
      font-size: 1.333rem !important;
      line-height: 3;
    }

    &__current-month {
      font-size: 1.333rem !important;
      margin-top: -0.417rem !important;
    }

    &__header {
      border-bottom: none !important;
      background-color: transparent !important;
    }

    &__navigation,
    &__navigation--next {
      top: 1.25rem !important;
    }

    &__day--keyboard-selected,
    &__month-text--keyboard-selected,
    &__quarter-text--keyboard-selected,
    &__year-text--keyboard-selected {
      background-color: #8BD8BD !important;
    }

    &__month
    div {
      &:first-child {
        &:nth-last-child(5) ~ * {
          padding-bottom: 0.633rem !important;
        }
        &:nth-last-child(6) ~ * {
          padding-top: 0.633rem !important;
        }
      }
    }
  }
}

.date-range-picker,
.date-picker {
  display: flex;
}

.react-datepicker-popper {
  padding-top: 2rem!important;
  z-index: 1000!important;
}

.react-datepicker {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
