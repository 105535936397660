.dashboard__chat__subscription-message {
  display: flex;
  height: 100%;
  background-image: url('../../../../assets/images/swytchboard-Y-size-big.svg');
  background-position: 5% 50%;
  background-repeat: no-repeat;
  background-color: white;
  text-align: center;
  border-radius: 1.25rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__title {
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  &__body {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: bold;
  }

  &__button {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: bold;
  }

  &__footer {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: bold;
  }

  &__contact-us:hover {
    color: $success;
  }
}
