$input-text-indent: 0.75rem; // This is used for the text indent in the timezone and the product website input

@import "styles/overrides";

.chat-settings {
  padding: 2rem 3.5rem;

  &__field {
    @extend .d-flex, .align-items-center !optional;
    color: $primary;
    font-family: 'Lato', sans-serif;
    margin: 1.75rem 0;
    &__label {
      width: 26rem;
      margin-right: 13rem;

      &__title {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      &__subtitle {
        font-size: 1rem;
      }
    }

    &__form-control{
      @extend .d-flex, .align-items-center !optional;
    }

    &__input,
    &__timezone,
    .timezone__control,
    {
      @include input-style;
      width: 275px;
      &:focus,
      .timezone__control--is-focused {
        @include input-focus-style;
      }
    }
  }

  // override text-indent for timepicker to center the input values
  .react-datepicker-wrapper .text-indent-none {
    text-indent: 0 !important;
  }
}
