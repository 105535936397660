.chat-queue-editable {
  margin-right: 1rem;
  max-height: 545px;
  overflow-x: hidden;
  width: 100%;
  flex-basis: auto;
}

.chat-queue-viewable {
  height: 100%;
  &__queue {
    background-color: $light;
    border-radius: 10px;
    padding: .7rem 1rem;
    margin-bottom: 1rem;
    width: 100%;
    
    span {
      width: auto;
      display: block;
      font-weight: bold;
      line-height: 1.6;
      white-space: pre-line;
    }
  }
}
