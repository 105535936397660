.video-controls-button {
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  filter: drop-shadow(0px 2px 16px rgba(36, 54, 101, 0.3));
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.5);
  &.mobile{
    box-shadow: 0px 2px 16px rgba(36, 54, 101, 0.3);
    background-color: rgba(255, 255, 255, 1);;
    &.disabled{
      background-color: #EC5A4B;
    }
  }
}

