.entry-point {
  display: flex;
  
  &__title {
    margin: auto 0;
    font-size: 16px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 2rem;
    flex-wrap: nowrap;
    
    &__link {
      display: flex;
      color: #767676;
      margin-right: 2rem;
      align-self: end;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:focus,
      &:active,
      &:hover {
        border: none;
      }
    }
  }
}
