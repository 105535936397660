.chat-ratings-editable {
  margin-right: 1rem;
  max-height: 545px;
  overflow-x: hidden;
  width: 100%;
  flex-basis: auto;
}

.chat-ratings-viewable {
  height: 100%;
  &__ratings {
    display: flex;
    padding: .3rem 0;

    p {
      flex: 1;
    }

    &__stars {
      flex: 3;
      padding: 0;
      text-align: end;
      margin-right: 1rem;
      svg {
        margin-left: .2rem;
      }
    }
  }

  &__input {
    border-radius: 5px !important;
    background-color: #F5F5F5 !important;
    width: 100% !important;
    padding-left: 2rem !important;
    border: none !important;
    resize: none;
    font-size: 12px !important;
    padding: 1rem;
  }

  &__checkbox {
    display: flex;

    label {
      padding-left: 1rem;
    }
  }
}
