.field {
  font-size: 16px;

  .form-group {
    margin-bottom: 0.5px;
  }
  .form-control {
    height: unset;
    padding: 0.75rem;

    &:focus {
      height: unset;
      box-shadow: 0 0 0 0.2rem rgba(14, 6, 65, 0.25);
      padding: 0.75rem;
    }

    &.error {
      box-shadow: 0 0 0 0.125rem rgba(255, 0, 0, 0.65);
    }
  }

  .text-from-field {
    font-size: 16px;
    color: $primary;
    border-radius: 23px;
    background: #F5F5F5;
    border: none;
    padding-left: 20px;
    margin-top: 0!important;

    &::placeholder {
      color: $default;
    }

    &:focus{
      font-size: 16px;
      border-radius: 23px;
      padding-left: 20px;
      border: none;
    }
  }
}
