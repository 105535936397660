.rating-field {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 250px;

  &__stars {
    width: 100px !important;
    display: flex !important;
    justify-content: space-between !important;
  }
}

.ratings-form {
  margin-top: 0.5rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  label {
    margin-bottom: 1.25rem;
  }
}
