.profile-settings__change-password {
  @extend .mb-3, .d-flex , .flex-column !optional;

  &__button {
    @extend .btn-danger !optional;
    width: 149px;
    margin-right: 0.5rem;
    height: 38px;
    padding: 0.5rem 1.5rem;
    font-size: 14px;
    font-weight: bold;
  }
}
