.chat-settings .toggle-buttons {
  display: flex;
  justify-content: space-between;
  width: 18rem;

  &__button {
    padding: 0.5rem 1.5rem;
    font-size: 1.15rem;
    height: 38px;
    font-weight: bold;

    &--success {
      @extend .btn-success !optional;
    }

    &--danger {
      @extend .btn-danger !optional;
    }

    &--disabled {
      box-shadow: none;
      background: #F5F5F5;
      color: $default;
      outline: none;
      border: none;
    }
  }
  &.extra-width {
    width: 23rem;
  }
}

