.customer-feedback {
  @extend .d-flex, .align-items-center, .justify-content-center !optional;
  padding: 1rem 1.5rem;

  &__img {
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
  }

  &__label {
    font-size: 16px;
    text-decoration: underline;
    color: #8BD8BD;
    padding: 0;
    padding-left: 10px;
    padding-bottom: 5px;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  &__link {
    color: $success;
    padding-left: 1rem;
    text-decoration: underline;
  }

  &__table {
    font-size: 1.333rem;
    text-align: start;
    height: 4.2rem;
    border-bottom: 2px solid $lighter;

    &__ellipsis {
      max-width : 25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }

    &__tooltip {
      font-size: 1rem;
      .tooltip-inner {
        max-width: 25rem;
      }
    }

    .helpfulness,
    .knowledge,
    .professional {
      text-align: center;

      span {
        padding-left: .5rem;
        vertical-align: middle;
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      display: flex;
    }

    &__active {
      font-weight: bold;
    }

    &__list {
      padding-top: 1rem;
      padding-bottom: 1rem;
      list-style: none;

      .previous,
      .next {
        border-radius: 5px;
        box-shadow: 0px 2px 10px rgba(36, 54, 101, 0.1);
        padding-bottom: .4rem;
      }

      a {
        padding-left: .8rem;
        padding-right: .8rem;
        font-size: 16px;
      }
    }
  }
}

.table-header {
  white-space: nowrap;
  .helpfulness,
  .knowledge,
  .professional {
    text-align: center;
  }
}
