.chat-settings__company-logo {
  @extend .d-flex, .justify-content-between, .align-content-center !optional;
  width: 26rem;

  &__spinner {
    padding-top: 0.75rem;
    width: 3.333rem;
    height: 3.333rem;
  }

  &__img {
    filter: drop-shadow(0px 2px 16px rgba(36, 54, 101, 0.1));
    border-radius: 50%;
    width: 3.333rem;
    height: 3.333rem;
  }

  input[type="file"] {
    display: none;
  }

  label {
    margin: 0;
  }

  &__default {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.333rem;
    height: 3.333rem;
  }

  &__button {
    @extend .d-flex, .align-content-center, .align-items-center, .justify-content-center !optional;
    cursor: pointer;
    font-size: 1.33333333rem;
    font-weight: normal;

    &--success {
      color: $success;
      text-decoration-line: underline;
    }

    &--danger {
      color: $danger;
      display: contents;
    }
  }
}
