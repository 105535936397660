p {
  size: 1.4rem;
}

// 18px
.section-title {
  font-size: 1.5rem;
}

// 16px
.card-title {
  font-size: 1.333333333rem;
}

// 14px
.card-subtitle {
  font-size: 1.166666667rem;
}

.consumer-name {
  text-align: end;
  font-weight: 700;
  &.next-customer {
    font-size: 1.333333333rem;
  }
}

#accept-next-customer {
  font-size: 1.333333333rem;
}

.consumer-conversation-type {
  text-align: end;
  font-weight: 700;
}
