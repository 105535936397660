.user-intro {
  @extend .d-flex, .align-items-center, .justify-content-start !optional;

  &__img {
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
  }

  &__content {
    padding-left: 2rem;
    padding-top: 1rem;
  }

  &__label {
    font-size: 16px;
    text-decoration: underline;
    color: #8BD8BD;
    padding: 0;
    padding-left: 10px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  &__button {
    @extend .btn-success !optional;
    width: 16rem;
    height: 3rem;
    font-size: 1.15rem;
    font-weight: bold;
    border: 0;
    border-radius: 15px;
    margin-bottom: 0.6rem;
  }

  &__datepicker-popper {
    display: none!important;
  }

  &__input-container {
    input {
      display: none!important;
    }
  }

}
