.messaging-layout-container {
  margin: 0 auto;
}

.messaging-layout {
  @extend .qr-mobile-screen !optional;
  background-color: $white;
  font-family: Nunito, sans-serif;
  width: 100%;
  height: 100vh;
  padding: 1.75rem 2.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 1rem;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #DADCE0;
      position: absolute;
      bottom: 0;
    }

    &__company-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      &__worker-name {
        font-size: 1.5rem;
        font-weight: bold;
      }

      &__logo {
        max-height: 1.875rem; //around 30px
      }
    }
  }

  &__btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.right{
      justify-content: end;
    }
    &__btn {
      @extend .qr-btn !optional;
      font-size: 1.175rem;
      padding: 0.5rem;
      width: 47%;

      &--danger {
        @extend .end-conv !optional;
        &--disabled {
          background-color: #fff !important;
          color: #ccc !important;
          border: 1px solid #cccccc !important;
        }
      }
    }
  }
}

.desktop-messaging-layout {
  @extend .qr-desktop-screen !optional;
  background-color: $white;
  font-family: Nunito, sans-serif;
  width: 100%;
  height: 100vh;
  padding: 1.75rem 2.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  &__header {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 1rem;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #DADCE0;
      position: absolute;
      bottom: 0;
    }

    &__company-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      &__worker-name {
        font-size: 1.5rem;
        font-weight: bold;
      }

      &__logo {
        max-height: 1.875rem; //around 30px
      }
    }
  }

  &__btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__btn {
      @extend .qr-btn !optional;
      font-size: 1.175rem;
      padding: 0.5rem;
      width: 47%;

      &--danger {
        @extend .end-conv !optional;
        &--disabled {
          background-color: #fff !important;
          color: #ccc !important;
          border: 1px solid #cccccc !important;
        }
      }
    }
  }

  .messaging-layout__header__company-info {
    display: none;
  }
  
  .messaging-layout__header {
    min-width: 27%;
    align-self: flex-end;

    &::after {
      display: none;
    }
  }

  .qr-chat__message__avatar--worker {
    max-width: 4%;
  }

  .qr-chat__message__info {
    flex: 0 0 100% !important;
    max-width: calc(100% - 4%) !important;
  }

  .qr-chat__message__avatar {
    max-width: 4%;
  }
}
