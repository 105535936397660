@mixin secondthumbnail($justify) {
  @if $justify {
    justify-content: space-around;
  }
  @else {
    justify-content: space-between;
  }
}

.video-container {
  width: 100%;
  height: 100%;
}

.participant-thumbnail {
  display: flex;
  // margin-left: 0;
  @include secondthumbnail(false)
}

.second-thumbnail {
  @include secondthumbnail(true)
}

.worker__thumbnail__chat__hidden {
  margin: 10px -2px 10px 0px;
  height: 120px !important;
}

.chat__hidden {
  margin: 0px 0px 10px 12px;
}

.video-thumbnail {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
  border-radius: 20px;
  height: 130px;
  width: 175px;
  background: black;
  object-fit: contain;
   &.worker-mobile{
    height: 105px;
    width: 140px;
    border-radius: 10px;
   }
}

.video-thumbnail-mobile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
  border-radius: 20px;
  background: black;
  height: 120px;
  width: 175px;

  .video {
    video {
      object-fit: cover !important;
      display: inline-block;
      margin-bottom: auto;
      max-height: 130px;
      transform: scaleX(-1);
    }
  }
}

.first-letter {
  font-size: 5em;
}

.video-thumbnail {
  .video{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: inline-block;  
    transform: scaleX(-1);
  }
}

.conversation-screen {
  margin: 1rem;
  display: flex;
  flex: 1;
  // max-height: 80vh;
  &__video {
    max-width: 1500px;
    flex-grow: 1;
    margin-top: 0.5rem;

    &__video-player {
      margin: 0 1rem;
      display: grid;
      height: calc(100% - 15%);

      &-content {
        margin-top:15px;
        position: relative;
        max-width: 1500px;
        display: grid;
        grid-template-columns: 1fr fit-content(30%);
        grid-template-rows: 100%;
        grid-area: 1 / 1 / 1 / 3;

        &.mobile{
          margin: 0px;
          display: flex;
          align-self: stretch;
          width: 100%;
          height: 100%;
        }
        .participant-info {
          @include connecting-style;
          height: 60vh;
          max-width: initial;
          

          .video {
            height: 60vh;
            video {
              height: inherit;
              max-width: 1500px;
            }
          }

          &.mobile{
            height: 100%;
            display: flex;
            align-self: stretch;
            width: 100%;
            background-color: black;
            align-items: center;
            justify-content: center;
            .video {
              height: 100%;
              width: 100%;
              video {
                width: 100%;
                height: 100%;
              }
            }
          }
          
          &.self-camera {
            .video {
              video {
                transform: scaleX(-1);
              }
            }
          }

          &.screen-share {
            .connecting {
              background: transparent;

            }
            .video {
              background-color: black;
              height: 60vh;
              border-radius: 20px;
              video {
                object-fit: contain;
                transform: scaleX(1);
                aspect-ratio: 16 / 9;
              }
            }
          }          
        }

        .participant-list {
          @include connecting-style;
          background: transparent;
          grid-area: 1 / 1 / 1 / 3;
          z-index: 100;
          margin: 1rem;
          position: absolute;
          max-width: 30%;
          right: 1rem;
          height: 150px;
          width: 150px;

            .hide-participant {
              display: none;
            }

          .show-participant {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  &__chat {
    display: flex;
    flex-direction: column;
  }
}

.is-text-only-screen {
  // height: 74vh !important;
  .conversation-screen__video {
    background-color: #F7F9FF;
    height: 100% !important;
  }
  .conversation-screen__video__chat-message {
    height: 100% !important;
    .chat-message__avatar {
      justify-content: center;
    }
    .chat-wrapper {
      .message-wrapper {
        flex: 1 1;
        overflow-y: auto;
        #input-messages {
          div:first-child {
            display: flex ;
            justify-content: center;
            height: 100%;
            flex-direction: column;
          }
        }
      }
    }
  }

  .conversation-screen__chat {
    height: 100%;
    width: 400px;
  }
}

@media screen and (max-width: 992px) {
  .conversation-screen {
    flex-direction: column;
    
    .conversation-screen__video {
      margin-bottom: 5rem;
    }
  }
}

.room-container {
  min-height: 60vh;
  z-index: 10;
  grid-area: 1 / 3 / 1 / 1;
  height: auto;
}

.video-buttons {
  grid-area: 2 / 1 / 2 / 3;
  display: flex;
  max-width: 1500px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-height: 100px;
  &.mobile{
    position: absolute;
    flex-direction: column;
    right: 0;
    top: 2.5vh;
    max-height: 100%;
    align-items: flex-end;
  }
}



