html,
body {
  font-size: 12px;
  color: $primary;
  background-color: $white;
}

.App {
  min-height: 100vh;
  background-color: $light;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &-sidebar {
    max-width: 18rem;
  }
  
  &-sidebar-small {
    max-width: 5rem;
  }

  &-sidebar-mobile {
    max-width: 8vh;
  }


  &-mobile{
    min-height: auto;
    background-color: $white;

    &-header{
      height: 8vh;
      box-shadow: 0px 2px 16px rgba(36, 54, 101, 0.1);
      z-index: 1;
      align-items: center;
      justify-content: space-evenly;
      .logo{
        max-width: 150px;
        margin-top: 5px;
      }
      p{
        padding: 0px;
        margin: 0px;
      }

    }
    &-content{
      height: 90%;
    }
  }
  &-content {
    width: calc(100% - 18rem);
  }
  .is-in-conversation-screen {
    // background-color: $lighter !important;
  }
}

p {
  font-size: 1.166666667rem;
  color: $primary;
}

.br-0 {
  border-radius: 0 !important;
}

.br-04 {
  border-radius: 0.416666667rem;
}

.br-05 {
  border-radius: 0.833333333rem;
}

.br-1 {
  border-radius: 1.25rem;
}

.br-2 {
  border-radius: 1.666666667rem;
}

.border-b {
  border-bottom: 1px solid $primary;
}

.brt-1 {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.brt-05 {
  border-top-left-radius: 0.833333333rem;
  border-top-right-radius: 0.833333333rem;
}

.text-overflow-hidden {
  display: block;
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.box-shadow-none {
  box-shadow: none !important;
}

.line-break {
  line-height: 30px
}

audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline,
audio::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-fullscreen-button{
  display: none;
}

#videoElement {
  filter: FlipH;
  -ms-filter: "FlipH";
}

.video-wrapper {
  position: relative;
  &::before {
    content: "Not broadcasting, not recording!";
    position: absolute;
    z-index: 999;
    @include setClamp(font-size, 8px, 1vw, 18px, 18px);
    top: 10px;
    right: 10px;
    opacity: 0.7;
    background-color: white;
    border-radius: 10px;
    padding: 0rem .4rem;
  }

  video {
    display: block;
    width: 100%;
    border-radius: 20px;
    aspect-ratio: 16 / 11;
    transform: scaleX(-1);
  }

  &.qr_flow {
    &::before {
      @include setClamp(font-size, 8px, 1vw, 11px, 11px);
      border-radius: 5px;
    }
    video {
      border-radius: 10px;
    }
  }
}

video {
  display: block;
  width: 100%;
  border-radius: 20px;
  max-width: 1000px;
  object-fit: cover;
  aspect-ratio: 1;
  &.screenShareOnly {
    min-width: 125vh;
    max-width: 125vh;
    height: 100%;
    aspect-ratio: 0;
    border-radius: 5px;
    object-fit: contain;
  }
}

.screen-share--only{
  position: absolute;
  .videoWaterMark{
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.8;
    z-index: 1;
    object-fit: contain;
    width: 3.7%;
    &.default {
      width: 2%;
    }
  }
  .full-screen-controls{
    background-color: rgba($color: #3D3D3D, $alpha: 0.4);
    position: absolute;
    bottom: 0;
    z-index: 1;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    height: 0;
    transition: .1s ease;
    display: none !important;
  }
}

.screen-share--only:fullscreen{
  video {
    &.screenShareOnly {
      min-width: 135vh;
      max-width: 100%;
      height: 100%;
      aspect-ratio: 0;
      border-radius: 5px;
      transform: scaleX(1);
      object-fit: contain;
    }
  }
  .videoWaterMark{
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.8;
    z-index: 1,
  }
  .full-screen-controls{
    display: flex !important;
    height: 4rem;
  }
}

video::-webkit-media-controls {
  visibility: none;

}

.sb-avatar__text {
  // The default fontsize of react-avatar doesn't properly align the text vertically
  font-family: Lato, sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}
