.qr-dialog {
  font-family: Nunito, sans-serif;
  margin: auto;
  border-radius: 0.85rem;
  padding: 1rem 1.25rem !important;
  border: 0;

  &__header {
    display: flex;
    justify-content: center;
    padding: 0.25rem 0 1rem;
    @include setClamp(font-size, 1.4rem, 5.5vw, 1.5rem, 1.5rem);
    position: relative;
    border: 0;
    font-weight: bold;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: #DADCE0;
      width: 90%;
    }
  }
  &__body {
    p {
      @include setClamp(font-size, 1.2rem, 5vw, 1.333333rem, 1.333333rem);
      margin-bottom: 1.75rem;
    }
    .video  {
      position: relative;
      video {
        display: block;
        width: 100%;
        border-radius: 10px;
        aspect-ratio: 16 / 11;
        transform: scaleX(-1);
      }
      .camera__disabled {
        display: block;
        width: 100%;
        border-radius: 10px;
        aspect-ratio: 16 / 11;
      }
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
  }

  &__button {
    @extend .qr-btn !optional;
    width: 45%;
    padding: 0.5rem;

    &--white {
      @extend .white !optional;
      &--disabled {
        background-color: #e8e8e8 !important;
        color: black !important;
      }
    }
  }

  &__message {
    padding-right: 1.4rem;
  }

  &__link {
    color: blue;
    font-weight: bold;
    text-decoration: underline;
  }
}


.dialog_container {
  max-width: 80vh;
}