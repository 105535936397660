.billing__plan-options-dialog {

  &__main-card {
    // had to override react Modal styles of min-width: 500px
    min-width: 960px;
  }

  &__option {
    min-width: 290px;
  }
}

.upgrade-dialog .horizontal-line {
  content: '';
  position: absolute;
  height: 2px;
  background-color: #F7F9FF;
  width: 98%;
  margin-left: 1%;
}

.upgrade-dialog {
  font-family: Lato, sans-serif;
  padding: 2.25rem 2rem 1.5rem;
  border: solid 1px #0E0641;

  &__header-wrapper {
    @include setClamp(font-size, 1.4rem, 5.5vw, 1.5rem, 1.5rem);
    position: relative;
    border: 0;
    padding: 0 0 1rem 0;

    &::after {
      @extend .horizontal-line;
      bottom: 0;
    }
  }

  &__header-content {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0 1.5rem;

    &__icon {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }

  &__body {
    padding: 1rem 1.5rem 1.25rem;

    p {
      @include setClamp(font-size, 1.2rem, 5vw, 1.333333rem, 1.333333rem);
      margin-bottom: 1.75rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    border: 0;
    position: relative;
    width: 100%;
    padding: 1rem 0 0 0;

    &::after {
      @extend .horizontal-line;
      top: 0;
    }

    .btn {
      @include setClamp(font-size, 1.2rem, 5vw, 1.333333rem, 1.333333rem);
      width: 42%;
      padding: 0.5rem 0.25rem;
      font-weight: bold;
    }
  }
}

