.queue-info {
  background: #f5f5f5;
  border-radius: 5px;
  @include setClamp(padding, 0.75rem, 3.5vw, 1rem, 0.8rem);
  margin-bottom: 1.5rem;
  font-weight: bold;

  p:last-child {
    margin-bottom: 0;
  }
}

#notification-toggle {
  width: 25px;
}
