.video-call-layout {
  @extend .qr-mobile-screen !optional;

  background-color: $white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.video-call-desktop-layout {
  @extend .qr-desktop-screen !optional;

  background-color: $white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__title {
    font-family: "Nunito", sans-serif;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    h4 {
      @include setClamp(font-size, 1rem, 4vw, 1.5rem, 1.5rem);
    }
  }

  &__content {
    flex: 1;
    display: flex;
    margin: 2.5rem;
    position: relative;

    .qr-video {
      max-height: 80vh;
      height: 100% !important;
      max-width: 1500px;

      .qr-video--connecting {
        width: 100% !important;
      }

      video {
        height: 100% !important;
        max-width: 1500px;
      }
      .screen-share {
        .video {
          background-color: black;
          video {
            object-fit: contain !important;
            transform: scaleX(1);
            aspect-ratio: 16 / 9;
          }
        }

      }

      .qr-video__buttons {
        flex-direction: row;
        width: max-content;
        bottom: 0;
        top: auto;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
        margin-bottom: 3rem;

        button {
          margin: auto 1rem;
        }
      }

      .qr-video__permissions {
        flex-direction: column;
        width: max-content;
        bottom: 5rem;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
        margin-bottom: 3rem;
        background-color: white;
        height: 9.5rem;
        width: 27rem;
        padding:1rem;

        button {
          margin: auto 1.5rem;
          width: 100%;
          height: 3rem;
          border-radius: 5px;
          border-width: 0px;
          background-color: $qr-main;
          padding: 0px;
        }
      }

      &__media-error-message {
        bottom: 100px;
        color: grey;
      }
    }

    .qr-chat-header {
      position: initial;
      z-index: 2;
    }

    .qr-chat-header--person {
      white-space: nowrap;
      width: max-content;
      z-index: 2;
    }

    .qr-chat-header--logo {
      right: 10px;
      z-index: 2;
    }

    .qr-chat--half-screen {
      max-height: 80vh;
      max-width: 450px;
      height: 100% !important;
      padding-bottom: 0;
    }
  }

  &__footer {
    margin-bottom: 1.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 920px) {
  .video-call-desktop-layout {
    &__content {
      flex-direction: column;

      .participant-list {
        height: 170px;

        .show-participant {
          height: inherit;

          .video {
            height: inherit;
          }
        }
      }

      .qr-video {
        width: 100% !important;
        height: 40vh !important;

        .qr-video--connecting {
          width: 100% !important;
        }

        .video-container {
          height: 100%;
        }

        .qr-video__buttons {
          margin-bottom: 4rem;
        }
      }

      .qr-chat--half-screen {
        max-width: 100% !important;
      }
    }

    .qr-chat-header--logo {
      right: 0%;
    }

    .qr-chat-header {
      position: relative;
    }

    .hide-chat-icon-container {
      display: none !important;
    }
  }
  .qr-video__permissions{
    top: calc(70% - #{$video-margin-bottom});
    background-color: white;
    padding: 10px;
    margin: 0.8rem;
    button {
      margin: auto 1.5rem;
      width: 100%;
      height: 3rem;
      border-radius: 5px;
      border-width: 0px;
      background-color: $qr-main;
      padding: 0px;
    }
    &.chat-hidden {
      top: calc(80% - #{$video-margin-bottom});
    }
  }
}

@media screen and (max-width: 750px) {
  .video-call-desktop-layout {
    &__content {
      .participant-list {
        height: auto;
      }
    }
  }
  .qr-video__permissions{
    top: calc(63% - #{$video-margin-bottom});
    background-color: white;
    padding: 10px;
    margin: 0.8rem;
    button {
      margin: auto 1.5rem;
      width: 100%;
      height: 3rem;
      border-radius: 5px;
      border-width: 0px;
      background-color: $qr-main;
      padding: 0px;
    }
    &.chat-hidden {
      top: calc(70% - #{$video-margin-bottom});
    }
  }
}
