.personal-info {
  &__button {
    width: 100%;
    height: 44px;
    font-size: 16px;
    line-height: 0;
    font-weight: bold;
    &--back {
      background: #fff;
      border: 1px solid #0e0641;
      color: #0E0641;
    }
    &--submit {
      @extend .btn-success !optional;
    }
  }
}
