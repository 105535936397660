.accept-invite {
  height: 100vh!important;
  width: 100vw!important;

  &--card {
    width: 43.583rem;
    height: 22.083rem;
    margin: auto;

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 1.53333px 12.2667px rgba(36, 54, 101, 0.1);
      border-radius: 15px;

      &-title {
        margin-bottom: 2rem;
        h4 {
          font-size: 2rem;
          padding-bottom: 1.5rem;
        }
      }
      &-content {
        padding: 0rem 5rem;
        text-align: center;
        :first-child{
          padding-bottom: 1rem;
        }
        p {
          font-size: 1.333rem;
        }
      }
    }
  }
}