.conversation-header {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    hr {
      background-color: $primary;
      height: 1px;
      margin: 0;
      margin-top: 1rem;
      box-shadow: 0px 2px 2px -15px rgba(36, 54, 101, 10);

    }

    box-shadow: 0px 2px 2px -15px rgba(36, 54, 101, 10);
  }
  &__go-back{
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
    }
  }
  &__content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    max-height: 40px;
    padding: 0 15px; // same from .col
    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
      max-height: 135px;

    }
    
    hr {
      background-color: $primary;
      height: 1px;
      margin: 0;
      margin-top: 1rem;
      box-shadow: 0px 2px 2px -15px rgba(36, 54, 101, 10);

    }

  }

  &__consumer {
    display: flex;

    &__avatar {
      margin-right: 1rem;
    }

    &__circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      margin: 0px 10px 0px 0px;
      p {
        font-size: 18px;
      }
    }
  
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__name {
        font-weight: bold;
        font-size: 16px;
      }

      &__activity-status {
        font-weight: 400;
        font-size: 12px;
      }

      &__email {
        font-weight: 400;
        font-size: 12px;
        color: #767676;
      }
    }
    @media only screen and (max-width: 600px) {
      width:100%;

    }
  }

  &__queued-customers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem;
    background-color: #fff;
    box-shadow: 0 2px 16px rgba(36, 54, 101, 0.1);
    border-radius: 1.5rem;
    height: 100%;
    font-family: Lato, sans-serif;


    &__text {
      font-weight: bold;
      color: $primary;

      &--red {
        margin-left: 1.35rem;
        color: $danger;
        font-size: 16px;
      }
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin:10px 0px;
    }
  }

  &__end-conversation-button {
    @extend .btn-danger !optional;
    min-width: 145px !important;
    font-weight: bold !important;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &__request-conversation-button {
    @extend .btn-primary !optional;
    min-width: 145px !important;
    font-weight: bold !important;
    font-size: 14px;
    line-height: 100%;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
  
  &__leave-conversation-button {
    min-width: 145px !important;
    font-weight: bold !important;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
}
