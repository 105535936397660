.sign-in {
    display: flex;           /* establish flex container */
    flex-direction: column;  /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;     /* center items horizontally, in this case */
    height: 100vh;
}

.login {
    min-width: 300px;
}

.remember-me {
    padding-left: 2rem;
    label {
        padding-left: 1rem;
    }
}
