.chat-message-dashboard {
  &__messages{
    height: 60vh;
    overflow-y: scroll;
    place-items: flex-end;
    &.mobile{
      height: 95%
    }
  }
  padding-bottom: 1.2rem;
  .avatar {
    img{
      border-radius: 10px;
      width: 40px;
      height: 40px;
    }
    .rounded {
      border-radius: 50%!important;
      width: 30px;
      height: 30px;
    }
  }
  .worker {
    .message {
      background-color: $primary;
      color: $white;
    }
  }

  .consumer {
    .message {
      background-color: $white;
      color: $primary;
    }
    .avatar {
      img{
        border-radius: 10px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .send-message {
    font-weight: 400;
    font-size: 1.16666666667rem;
    columns: $primary;
    padding: 1rem;
    width: 100%;
    border: 1px solid $default;

    &::placeholder {
      color: $default;
    }

    &.active,
    &.focus {
      border: 1px solid $default;
    }
  }
}

.team-chat-wrapper {
  .message {
    font-size: 1.166666667rem;
  }

  &__company-logo {
    filter: drop-shadow(0px 2px 16px rgba(36, 54, 101, 0.1));
    border-radius: 50%;
    width: 3.333rem;
    height: 3.333rem;
    padding: 0.25rem;
  }

  input {
    border: none;
    border-radius: 0;
    box-shadow: none !important;

    &:focus,
    &:active {
      outline: none;
      border: none;
      border-radius: 0;
    }
  }
  .channel-name {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.833333333rem;
  }

  .channels {
    width: 100%;

    .dropdown {
      width: 100%;
    }

    .btn {
      text-align: left;
      font-size: 1.25rem;
      line-height: 1.6rem;
      width: 100%;
      border-radius: 0;
      position: relative;

      &:after {
        content: none;
      }

      &:hover {
        border: 1px solid $primary;
      }

      &:active,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .dropdown-icon {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .dropdown-menu {
      width: 100%;

      .dropdown-item {
        font-size: 1.25rem;
        line-height: 1.6rem;
        padding: 1rem;
      }
    }
  }
}

.permissions-wrapper {
  background-color: rgba(0, 0, 0, 0.9);

  .permission-message {
    color: $white;
    font-size: 2rem;
  }
}

.request-video {
  @extend .w-100, .p-3, .br-2, .shadow-default, .font-weight-bold, !optional;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 221px;
  text-align: center;
  background-color: $white;
  font-size: 1.2rem;
  &__header {
    @extend .mb-3 !optional;
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    .btn {
      font-size: 1rem;
      padding: 0.25rem;
      width: 42%;
      font-weight: bold;
    }
  }

  &__timestamp {
    @extend .w-100, .font-italic, .pt-2, .ps-3 !optional;
    color: $default;
  }
}

.hide-conversation-screen-chat {
  display: none !important;
}

.hide-chat-icon-container {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: -10px;
  right: 0px;
  .hide-chat-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
