.queue-container {
  padding: 10px 0px !important;
  overflow-y: auto;
  height: 40svh;

  scrollbar-width: thin;
  scrollbar-color: $primary-light white;
}

.queue-container::-webkit-scrollbar {
  width: 6px;
}
.queue-container::-webkit-scrollbar-thumb {
  background-color: $primary-light;
  border-radius: 20px;
}

.queue-row {
  display: flex;
  flex-flow: row wrap;
}

.queue {
  font-size: 12.4px;

  &__entry-point {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__message {
    margin-top: 5px;
  }

  &__email {
    font-weight: 400;
    color: #767676;
  }
}

.queue__timer {
  background-color: #FBDEDB;
  color: #EC5A4B;
  border-radius: 4px;
  padding: 1px 4px;
  gap: 10px;
  font-size: 14px;
}

.queue__timer:before {
  content: attr(data-hover);
  opacity: 0;
  background-color: transparent;
  color: black;
  padding-top: 2rem;
  text-align: center;
  transition: opacity 1s ease-in-out;
  font-size: x-small;
  font-weight: 200;
  position: absolute;
  z-index: 1;
}

.queue__timer:hover:before {
  opacity: 1;
  visibility: visible;
}
