.verify-email {
  &__h3 {
    font-size: 24px;
  }

  &__link {
    font-size: 16px;
  }

  &__text {
    text-align: justify;
    font-size: 16px;

    &--green {
      color: #8BD8BD;
    }
    &--pointer {
      cursor: pointer;
    }
  }

  &__resend-verification-loading {
    cursor: progress;
  }

  &__button {
    width: 40%;
    height: 44px;
    font-size: 16px;
    line-height: 0;
    font-weight: bold;
    &--back {
      background: #fff;
      border: 1px solid #0e0641;
      color: #0E0641;
    }
    &--submit {
      @extend .btn-success !optional;
    }
  }
}
