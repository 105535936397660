.add-entry-point .horizontal-line {
  content: '';
  position: absolute;
  height: 2px;
  background-color: #F7F9FF;
  width: 98%;
  margin-left: 1%;
}

.add-entry-point {
  @extend .btn-success !optional;
  position: relative;
  width: 100%;
  height: 38px;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  font-weight: bold;

  &::after {
    @extend .horizontal-line;
    left: 0;
    bottom: 0;
  }
}
