.text__file__preview {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 0.5em;
  margin-right: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 100%;

  &__name {
    line-height: 14px;
    color: #0E0641;
    margin-left: 6%;
  }

  &__type {
    line-height: 14px;
    color: #767676;
    margin-left: 6%;
  }
}


.close__icon {
  position: absolute;
  top: -10;
  right: -10;
  cursor: pointer;
}