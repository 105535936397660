.checkout__content {
  max-width: 450px;
  margin-bottom: 5rem;
  position: relative;

  &__plan-prices {
    border: 1px;
    text-align: center;
    border-radius: 0.3rem !important;
    font-weight: bold;
    padding: 10rem 6rem 3rem 6rem;
    background-color: $white;
    margin-bottom: 5rem;
    transition: transform 300ms ease-out;
    transform: scale(1);
  }

  &__plan-prices:hover {
    transition: transform 300ms ease-in;
    transform: scale(1.1);
  }

  &__btn-select {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    width: 121px;
    height: 38px;
    padding: 0.5rem 1.5rem;
    transition: background-color 300ms ease-in-out;
  }

  &__plan-features {
    margin-left: 3rem;
  }

  &__plan-features__description {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $border;
    color: $default;
  }

  &__plan-features__ul {
    padding: 0;
    margin: 0;
    font-size: 15px;
    list-style-type: none;
  }

  &__plan-features__li {
    margin-bottom: 1rem;
    color: $default;
  }
}
