.chat-settings {
  // Reset timezone dropdown margin and padding to 0
  .timezone__value-container,
  .timezone__placeholder,
  .timezone__single-value,
  .css-b8ldur-Input, {
    margin: 0;
    padding: 0;
  }

  // Add margin-left to the timezone to apply same indentation as other inputs
  .timezone__single-value,
  .timezone__placeholder, {
    margin-left: $input-text-indent !important;
  }

  // Override bootstrap form-group margin
  .form-group {
    margin: 0;
  }

  // Remove unnecessary dropdown for the time-pickers (used in Office Hours)
  .react-datepicker-popper {
    display: none;
  }

  // Center checkbox and label vertically
  .form-check {
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: 2rem;
    font-size: 1.15rem;
    line-height: 100%;
  }
  .form-check-input {
    position: relative;
    margin: 0 5px 0 0;
  }

}
