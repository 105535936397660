.profile-settings {
  padding: 2rem 3.5rem;

  .form-group {
    margin: 0;
  }

  &__field {
    @extend .d-flex, .align-items-center !optional;
    color: $primary;
    font-family: 'Lato', sans-serif;
    margin: 1.75rem 0;

    &__label {
      width: 26rem;
      margin-right: 7rem;
      align-self: start;

      &__title {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      &__subtitle {
        font-size: 1rem;
      }
    }

    &__input {
      @extend .sb-input;
      width: 275px;
    }
  }

  &__divider {
    position: relative;
    margin-bottom: 4rem;

    &::after {
      position: absolute;
      bottom: 0;
      content: '';
      width: 100%;
      height: 2px;
      background-color: #F7F9FF;
    }
  }
    &__delete-account {
      color: #EC5A4B;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
}
