$video-margin-bottom: -30px;

.qr-video {
  position: relative;
  margin-bottom: $video-margin-bottom;
  height: 60vh !important;
  width: 100% !important;

  video {
    margin-bottom: $video-margin-bottom;
    height: 60vh !important;
    width: 100% !important;
    object-fit: cover !important;
    border-radius: 0;
    aspect-ratio: 1;
  }

  &--connecting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: inherit;

    p {
      color: white;
    }
    div[class="spinner-border text-primary"] {
      color: white !important;
    }
  }

  .participant-info {
    @include connecting-style;

    height: inherit;

    .video {
      height: inherit;
    }

    &.screen-share {
      @include connecting-style;
      .qr-video--connecting {
        background: black;
      }

      .video {
        video {
          transform: scaleX(1);
        }
      }
    }
  }

  .participant-list {
    position: absolute;
    max-width: 30%;
    top: 1rem;
    right: 1rem;
    z-index: 100;

    .show-participant {
      margin-bottom: 1rem;

      .video {
        video {
          border-radius: 20px;
          height: 100px !important;
        }
      }
    }

    .hide-participant {
      display: none;
    }
  }

  &__buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 1rem;
    top: calc(20% - #{$video-margin-bottom});
    z-index: 100;

    &__btn {
      margin-top: 1rem;
      border-radius: 1rem;
      background-color: $white;
      box-shadow: 0 0 5px rgba(61, 64, 67, 0.25);
      &.webcam_preview {
        padding: 0.4rem !important;
        border-radius: 0.6rem;

      }
      &.inactive {
        background-color: #C94031;
        border: 1px solid #C94031;
      }
    }
  }

  &__permissions {
    position: absolute;
  }


  &__media-error-message {
    position: absolute;
    bottom: 50px;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 0;
    padding: 0.75rem;
    z-index: 1;
    text-align: center;
    color: grey;
  }
}

.room {
  &-container {
    position: relative;
    height: 100%;
  }
}
.full-screen-video {
  height: 100vh !important;

  video {
    height: 100vh !important;
  }
}

.full-screen-screenshare {
  background: black;
  video {
    object-fit: contain !important;
  }
}