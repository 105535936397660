@import 'assets/styles/variables.scss';

.admin-dashboard-stats {
  color: $default;

  &__header {
    min-height: 48px;
  }

  &__content {
    color: $primary!important;
  }

  &__badge {
    border-radius: 1rem;
    width: 3rem;
    
    &.badgered {
      color: #EC5A4B;
      background-color: rgba(236, 90, 75, .2);
    }
    
    &.badgegreen {
      color: #317E63;
      background-color: rgba(139, 216, 189, .2);
    }
    
    &.badgegrey {
      color: #767676;
      background-color: rgba(118, 118, 118, .2);
    }
  }
}

.slider-container{
  justify-content: center;
  margin-left: 4rem;
  margin-right: 4rem;

  &--button {
    padding: .7rem;
    margin-left: .7rem;
    margin-right: .7rem;
    background: none;
    border: none;
  }
}
