.qr-mobile-screen {
  max-width: 430px;
  margin: 0 auto;
}

.qr-desktop-screen {
  max-width: 100%;
  margin: 0 auto;
}

.qr-container {
  @extend .qr-mobile-screen !optional;
  font-family: "Nunito", sans-serif;
  font-size: 1.25rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 430px;

  padding-top: 3.25rem;
  @include setClamp(padding-left, 1.25rem, 10.5vw, 3.5rem, 3.25rem);
  @include setClamp(padding-right, 1.25rem, 10.5vw, 3.5rem, 3.25rem);
  padding-bottom: 1.25rem;

  color: $qr-text-color;
  overflow-wrap: break-word;

  p {
    @include setClamp(font-size, 12px, 4.5vw, 16px, 16px);
  }

  // Styles for heading with blue underline (See figma design)
  &__title {
    margin-bottom: 1.5rem;
    text-align: left;
    letter-spacing: 0.02em;
    @include setClamp(font-size, 1.5rem, 7vw, 2rem, 1.75rem);
    line-height: 150%;
    font-weight: bold;
    padding-bottom: 0.5rem;
    position: relative;

    &__line {
      content: '';
      bottom: 0;
      left: 0;
      @include setClamp(width, 35px, 11.75vw, 45px, 42px);
      height: 4px;
      position: absolute;
      background: #3273E0;
      border-radius: 10px;
    }
  }
  &__queue {
    display:flex;
    flex-direction: column;
    align-items: center;
    p {   
      font-family: "Nunito", sans-serif;
      color: #3D4043;
      &.bold{
        font-weight: bold;
      }
    }
  }
  

  &__spinner {
    display: flex;
    height: 100vh!important;
  }

  form {
    .form-group {
      margin-bottom: 0rem;
    }

    .form-label {
      font-weight: bold;
      @include setClamp(font-size, 12px, 4.5vw, 16px, 16px);
    }

    // checkbox label
    .form-check label {
      font-weight: normal;
      padding-left: 0.5rem;
      font-size: 1.175rem; // around 14px
      width: 100%;
      text-align: left;
    }

    .form-check input {
      width: 15px;
      height: 15px;
    }
  }

  .screen-intro-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    p {
      line-height: 2rem; //24px
      margin-bottom: 1.5rem;
      white-space: pre-line;
      padding: .3rem;
      
      &.first-line {
        &::first-line{
          font-weight: bold!important;
        }
      }
    }

    &--center {
      text-align: center;
    }

    &--stretch {
      flex-grow: 1;
      margin-bottom: 0;
    }

    &--increase-spacing {
      p {
        @include setClamp(margin-bottom, 1.25rem, 2.7vh, 1.85rem, 1.85rem);
      }

    }
}}

.activity-indicator {
  margin-right: 8px;
  background-color: $danger;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &__connected {
    background-color: $success;
  }
  &__disconnected {
    background-color: $danger;
  }
}
