.chat-settings__header {
  @extend .w-100, .d-flex, .justify-content-between, .align-items-center !optional;
  margin-top: -1rem; // To revert the positive margin from the user stats component
  font-family: 'Lato', sans-serif;
  color: $primary;
  position: relative;
  padding-bottom: 1.25rem;

  // Bottom border-line for the header
  &::after {
    position: absolute;
    bottom: 0;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #F7F9FF;
  }

  &__title {
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }

  &__subtitle {
    font-size: 1.33333rem;
  }

  &__button {
    @extend .btn-success !optional;
    width: 121px;
    height: 38px;
    padding: 0.5rem 1.5rem;
    font-size: 14px;
    font-weight: bold;
  }
}
