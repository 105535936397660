@mixin setClamp($property, $min, $val, $max, $fallback){
  #{$property}: clamp(#{$min}, #{$val}, #{$max});

  @supports not ($property: clamp(#{$min}, #{$val}, #{$max})) {
    #{$property}: #{$fallback};
  }
}

// The input styles are created in mixins, so they can be used in third party libraries like react-datepicker,
// where it is easier to override styles with mixins
@mixin input-focus-style {
  background-color: $white !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  box-shadow: 0 0 0 0.2rem rgba(14, 6, 65, 0.25) !important;
  border-color: #2711b6 !important;
  outline: 0 !important;
}

@mixin input-style {
  color: #495057 !important;
  background-color: $light !important;
  border-radius: 1.7rem !important;
  height: 38px !important;
  max-height: 38px !important;
  font-size: 1.15rem !important;
  border: 0 !important;
  text-indent: 0.75rem !important;
  &.error {
    box-shadow: 0 0 0 0.075rem rgb(255, 8, 8) !important;
  }

  &:disabled{
    border: 1px solid $border !important;
    background-color: white!important;
    color: $default!important;
  }
  &:focus {
    @include input-focus-style();
  }
}

@mixin connecting-style {
  .connecting {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: black;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: auto;
    top: 0;
    bottom: 0;

    &.mobile{
      z-index: 0;
      border-radius: 0px;
      background-color: black;
    }
  
    p {
      color: white;
    }
  }
}
