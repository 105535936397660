.forgot-password {
  color: #8BD8BD;
  cursor: pointer;
}

.resend-code {
  color: #0073b1;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: inherit;
}
