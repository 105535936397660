.billing {

  &__btn-save-settings {
    font-size: 14px;
    width: 121px;
    height: 38px;
    padding: 0.5rem 1.5rem;
  }

  &__btn-edit-billing {
    font-size: 14px;
    width: 141px;
    height: 38px;
    padding: 0.5rem 1.5rem;
  }
}
