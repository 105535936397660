.profile-settings__profile-photo {
  &__spinner {
    display: flex;
    width: 14rem;
    height: 9rem;
  }

  &__container {
    text-align: center;
    padding-top: 1.2rem;
    width: 14rem;
    height: 9rem;
  }

  &__img {
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    margin: auto;
    width: 9rem;
    height: 9rem;
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__btn {
    @extend .d-flex, !optional;
    cursor: pointer;
    font-size: 1.33333333rem;
    font-weight: normal;
    text-decoration-line: underline;

    input {
      display: none;
    }
  
    label {
      margin: 0;
    }

    &--success {
      color: $danger;
    }

    &--danger {
      color: $success;
    }
  }
}

.ReactCrop__image {
  text-align: center;
  max-width: 400px;
  max-height: 400px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.react-crop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 300px;

  &__upload {
    &:hover {
      cursor: pointer;
    }
  }

  &__profile-photo__input {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    display: none;

    &:hover {
      cursor: pointer;
    }
  }

  &__preview {
    background-color: rgb(185, 185, 185);
  }
}
