.qr-chat {
  font-family: "Nunito", sans-serif;
  font-size: 1.16666666667rem !important;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  min-height: 0;

  &--half-screen {
    padding: 0 2.75rem 1rem;
    border-top-right-radius: 3rem;
    border-top-left-radius: 3rem;
    height: 60vh;
    z-index: 99; // to be over the video component
    flex-grow: 1; // To stretch to the bottom of the screen
  }

  &--hide-chat {
    .qr-chat__messages-container {
      display: none;
    }
    .qr-chat__message-list {
      display: none;
    }
  }

  &--connecting {
    justify-content: center;
    align-items: center;
  }

  &__messages-container {
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 1rem;
  }

  &__message-list {
    overflow-y: auto;
    width: 100%;
    padding-right: 0.75rem; // place for the scrollbar
  }

  &__message {
    width: 100%;
    margin: 0 0 0.75rem 0;
  }

  &__message__avatar {
    display: flex;
    margin-top: 0.25rem;
    padding: 0;

    &--worker {
      justify-content: flex-start;
      width: 30px;
      height: 30px;
    }

    &--consumer {
      justify-content: flex-end;
    }
  }

  &__message__info {
    padding: 0;

    &__content {
      overflow-wrap: break-word;
      border-radius: 0.5rem;
      padding: 1rem;

      &--consumer {
        color: $white;
        background-color: $qr-main;
      }

      &--worker {
        color: $qr-text-color;
        background-color: $light;
      }
    }

    &__timestamp {
      font-style: italic;
      font-size: 1rem;
      color: $default;
      padding: 0.35rem 0;

      &--consumer {
        text-align: right;
      }
    }
  }

  .hide-chat {
    background-color: white;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      background-color: $default;
      height: 5px;
      width: 45px;
    }
  }

  .show-chat-button {
    position: absolute;
    right: 0vw;
    margin-right: 15px;
    margin-top: -25px;
    z-index: 100;
    border: none;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    background-color: white;
    height: 34px;
    width: 34px;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 !important;
    padding-top: 10px;

    input[type="text"],
    input[type="text"]:active,
    input[type="text"]:focus {
      background-color: $white !important;
      border: 1px solid $border;
      border-radius: .5rem;
      padding-right: 4rem;
    }
  }
}

.qr-chat-hidden {
  max-width: 150px !important;
  padding: 0;
  //justify-content: end;
  .qr-chat__messages-container {
    display: none !important;
  }
  .qr-chat__input {
    display: none !important;
  }
}
