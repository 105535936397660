.user-stats .horizontal-line {
  content: '';
  position: absolute;
  height: 2px;
  background-color: #eff2fc;
  width: calc(95% - 10rem) ;
  margin-left: 2%;
}

.user-stats {  
  padding: 2rem 0.5rem !important;

  &__avatar {
    text-align: center;
    max-width: 10rem;
    img {
      border-radius: 50%;
      width: 7rem;
      height: 7rem;
    }
  }
  
  &__minimize__button {
    padding: .5rem;
    padding-right: 2rem;

    p {
      margin: 0!important;
      margin-right: .5rem!important;
      @include setClamp(font-size, 1.2rem, 5vw, 1.166666667rem, 1.333333rem);
    }

    &::before {
      @extend .horizontal-line;
      top: 10;
      left: 0;
    }
  }

  &__button {
    @extend .btn-success !optional;
    width: 16rem;
    height: 3rem;
    font-size: 1.15rem;
    font-weight: bold;
    border: 0;
    border-radius: 15px;
    margin-bottom: 0.6rem;
  }

  &__datepicker-popper {
    display: none!important;
  }

  &__input-container {
    input {
      display: none!important;
    }
  }

  &__label {
    font-size: 16px;
    text-decoration: underline;
    color: #8BD8BD;
    padding: 0;
    padding-left: 10px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
}
