.chat-intro-editable {
  margin-right: 1rem;
  max-height: 545px;
  overflow-x: hidden;
  width: 100%;
  flex-basis: auto;

  &__website-link,
  &__website-name {
    @extend .sb-input;
    color: $primary;
    margin: .8rem auto;
  }

  &__error {
    margin-top: 0.5rem;
    color: $danger;
  }
}

.chat-intro-viewable {
  height: 100%;

  &__away-message {
    background-color: #fff3cd;
    margin-bottom: 1rem;

    &.none {
      display: none;
    }

    p {
      padding: 1rem;
      margin: 0;
      color: #856404;
      text-align: center;
    }
  }
}
