@import 'assets/styles/variables.scss';

.nav-item {
  font-size: 1.333333333rem;
  a {
    text-align: center;
    color: $lighter;
    font-size: 1.333333333rem;

    &.active {
      background-color: rgba(255, 255, 255, 0.2);
      border-left: 3px solid $success;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .item{
    fill-rule: evenodd;
  }
}

.number-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.7rem;
  height: 1.7rem;
  background-color: $danger;
  border-radius: 100%;
  font-weight: bold;
  color: white;
  margin: 0px;
  margin: 0px;
  padding: 0px;
  font-size: 11px;
  margin-left: 5px;
}