input[type="text"],
input[type="text"]:active,
input[type="text"]:focus,
input[type="password"],
input[type="password"]:active,
input[type="password"]:focus,
input[type="email"],
input[type="email"]:active,
input[type="email"]:focus,
input[type="tel"],
input[type="tel"]:active,
input[type="tel"]:focus,
.qr-container .qr-textarea {
  background-color: $light;
  color: $primary;
  border-radius: $border-radius-base;
  padding: 1rem;
  border: 1px solid transparent;
  box-shadow: none;
  height: 100%;
  font-size: 1.25rem;
  outline: none;

  &:focus {
    border-color: $primary;
  }
}

.qr-input,
.qr-textarea {
  background-color: $light;
  border-radius: 5px !important;
  border: 1px solid transparent;
  color: $qr-text-color;
  &.error {
    box-shadow: 0 0 0 0.015rem rgb(255, 8, 8) !important;
    border-color: transparent !important;
  }
  &.rounded {
    border-radius: 20px !important;
  }
}

input[class~="qr-input"] {
  @include setClamp(font-size, 12px, 4.5vw, 16px, 16px);
}

// Center the checkbox input and label vertically
.form-check {
  display: flex;
  padding: 0;
  align-items: center;
}

.form-check-input {
  margin: 0 10px 0 0;
  position: unset;
}

// Base styling for input fields
.sb-input {
  @include input-style;
  &--focus,
  :focus {
    @include input-focus-style;
  }
}

// Style for react-select
.react-select {
  &__control {
    @include input-style;

    &--is-focused {
      @include input-focus-style;
    }

    .css-b8ldur-Input {
      margin: 0 !important;
    }
  }
  &__value-container,
  &__placeholder,
  &__single-value {
    margin: 0 !important;
    padding: 0 !important;
  }

  &__single-value,
  &__placeholder {
    margin-left: 0.75rem !important;
  }
}

.line-height-sm {
  line-height: 22px;
}
