.account-setup {
  &__header {
    @extend .d-flex, .justify-content-between, .align-items-center !optional;

    &__title {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
    }

    &__log-out {
      font-weight: bold;
      font-size: 16px;
    }
  }

  &__title {
    color: $primary;
    font-size: 16px;
    margin-bottom: 2rem;
  }

  .form-check {
    font-size: 14px;
  }

  &__button {
    width: 40%;
    height: 44px;
    font-size: 16px;
    line-height: 0;
    font-weight: bold;
    &--back {
      background: #fff;
      border: 1px solid #0e0641;
      color: #0E0641;
    }
    &--submit {
      @extend .btn-success !optional;
    }
  }

}
