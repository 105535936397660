.active-conversation {
    max-width: 200px;
    > .info-card {
        display:flex;
        align-items: center;
        height: 40px;
    > .title {
        margin: 0px;
        padding: 0px 10% 0px 0px;
        font-weight: bold;
        overflow:hidden; 
        white-space:nowrap; 
        text-overflow: ellipsis;
    }
    > .notification-circle {
        width: 1.7rem;
        height: 1.7rem;
        background-color: $danger;
        border-radius: 100%;
        text-align: center;
        font-weight: bold;
        color: white;
        margin: 0px;
        padding: 0px;
        padding-top: 1px;
        font-size: 12px;
    }
  }
}
