@import '../../../assets/styles/variables.scss';

.qr-chat-header {
  display: flex;
  flex-direction: column;
  top: 1rem;
  z-index: 1;
  position: relative;

  &--person {
    position: absolute;
    left: 0;
    color: $body-bg;
    height: 3.6rem;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
    background-color: #000;
    padding: 0.2rem 1rem;
    text-transform: uppercase;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.9rem;
    display: flex;
    align-items: center;

    &.off {
      padding-left: 1.5rem;
      text-transform: capitalize;
      background-color: transparent;
      color: $body-bg;
    }
  }

  &--logo {
    position: absolute;
    right: 1rem;

    img {
      object-fit: contain;
      width: 6.3rem;
      height: 3.6rem;
    }
  }
}
