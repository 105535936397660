.outer_container {
	width: 2em;
	height: 2em;
	padding: 0.9em;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
}

.inner_container {
	display: flex;
	align-items: flex-end;

	div {
		width: 2px;
		margin-right: 1px;
		div:not(div:last-child) {
			border-right: none;
		}
	}
}
