.entry-point-search-bar {
  &__title {
    margin: auto 0;
    font-size: 18px;
  }

  &__search-bar {
  position: relative;

    &__icon {
      position: absolute;
      cursor: pointer;
      top: 12px;
      left: 30px;
    }
  }

  &__input {
    height: 40px;
    font-size: 14px;
    border-radius: 20px;
    background-color: #F5F5F5;
    padding-left: 3rem;

    &:hover {
      cursor: pointer;
    }
    &:active,
    &:focus {
      cursor: auto;
    }
  }
}
