.conversation-messages {
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: 100%;
  height: 528px;
  display: flex;
  flex-direction: column;
  background-color: #F7F9FF;
  font-size: 12px!important;
  border-radius: 10px;
  position: relative;
  
  &__header {
    height: fit-content;
    width: inherit;
    display: flex;
    flex-direction: column;
    flex-grow: initial;
    padding-top: 1rem;
  
    hr {
      width: 100%;
      background: black;
    }
  }

  &__footer {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: initial;
    padding-bottom: 1rem;
    padding-top: .5rem;

    hr {
      width: 100%;
      background: black;
    }

    h5 {
      margin: 0;
    }
  }
}

.conversation-messages-list {
  line-height: normal;
}