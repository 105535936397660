@import './../../variables';

.chat-wrapper-consumer {
  .send-message {
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 1.16666666667rem !important;
    padding: 1rem;
    border-radius: 0 !important;
    width: 100%;
    border: 1px solid #767676;
    
    &.active,
    &.focus {
      border: 1px solid #767676;
      font-size: 1.16666666667rem !important;
    }
  }
}

.chat-message {
  margin: 0!important;
  padding: 1.2rem;
  font-size: 14px;

  &__avatar {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
    padding: 0rem .5rem;  
    width: 30px;
    height: 30px;

    &--worker {
      justify-content: flex-start;
    }

    &--consumer {
      justify-content: flex-end;
    }
  }

  &__info {
    padding: 0;

    &__content {
      overflow-wrap: break-word;
      border-radius: 1.8rem;
      padding: 1.5rem 1rem;

      &--consumer {
        color: #0e0641;
        background-color: $white;
      }

      &--worker {
        color: $white;
        background-color: #0e0641;

        :hover {
          color: white;
        }
      }
    }

    &__timestamp {
      font-style: italic;
      font-size: 1rem;
      color: #0e0641;
      padding: 0.35rem 0;

      &--consumer {
        text-align: right;
      }
    }
  }
}
