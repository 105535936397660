.punch-in-out-btn {
  @extend .btn-success !optional;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  font-size: 1.15rem;
  height: 34px;

  &--fixed-width {
    width: 121px;
  }

  &--max-width {
    width: 100%;
  }

  .spinner-border {
    width: 20px;
    height: 20px;
  }
}
.punched-in-timer {
  @include setClamp(font-size, 18px, 2.25vw, 22px, 22px);
  font-weight: 900;
}
