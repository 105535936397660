.logos-placeholder {
  display: flex;
  justify-content: space-evenly;
  
  img {
    max-width: 10rem;
    max-height: 3.75rem;
  }

  &--separator {
    border: 1px solid #DADCE0;
  }
}
