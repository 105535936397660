.conversation-details {
  font-size: 16px;
  line-height: 3;

  &__modal{
    width: 869px;
    max-width: 869px;
  }

  &__field {
    white-space: nowrap;
    display: flex;
    width: 100%;

    &.worker {
      &::before {
        @extend .horizontal-line;
        width: 92%!important;
      }
    }

    &__label {
      padding: 0;
      font-weight: bold;
    }

    &__content {
      color: #767676;
      padding: 0rem 2rem 0rem 1rem ;

      &.email {
        color: $success!important;
        text-decoration: underline;
      }

      &.star {
        span {
          padding-left: .5rem;
          vertical-align: middle;
        }
      }

      &.comment {
        padding-top: 1.4rem;
        margin: 0;
        line-height: normal;
        width: 220px;
        height: 100%;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }
  }
}
