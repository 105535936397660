// reset overridden bootstrap css
#settings-dialog {
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  .nav-tabs .nav-link {
    color: #0E0641;
  }
}
