.stepper-wrapper {
	display: flex;
	justify-content: space-between;
}
.stepper-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	&::before {
		position: absolute;
		content: "";
		border-bottom: 2px solid #CCCCCC;
		width: 100%;
		top: 7px;
		left: -50%;
		z-index: 2;
	}
	&::after {
		position: absolute;
		content: "";
		border-bottom: 2px solid #CCCCCC;
		width: 100%;
		top: 7px;
		left: 50%;
		z-index: 2;
	}
	.step-counter {
		position: relative;
		z-index: 5;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background: #CCCCCC;
		margin-bottom: 6px;
	}
	&:first-child {
		&::before {
			content: none;
		}
	}
	&:last-child {
		&::after {
			display: none;
		}
	}
}

.stepper-item.completed {
	.step-counter {
		background-color: $success;
	}
	&::after {
		position: absolute;
		content: "";
		border-bottom: 2px solid $success;
		width: 100%;
		top: 7px;
		left: 50%;
		z-index: 3;
	}
}

.step-name {
	color: $success;
}

.step-name.active {
	color: #CCCCCC;
}
