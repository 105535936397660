.header {
  .notice {
    font-size: 1.5rem;
    line-height: 1.8;
  }
  .user-info {
    img {
      border-radius: 50%;
      width: 3.333rem;
      height: 3.333rem;
    }
  }
  .user-submenu {
    right: 0;

    .submenu-item {
      font-size: 1.5rem;
      line-height: 1.833333333rem;
      padding: 1.5rem 0;
    }
  }

  .user-profile {
    .dropdown-toggle::after {
      content: none;
    }
  }
}

#dropdown-basic {
  background-color: #0E0641 !important;
}

#dropdown-basic:hover {
  background-color: #3e3867 !important;
}